import { defineAction, object, enumerated, string } from '~/prix'
import fetchDistrictsAction from './fetchDistricts.action.json'

const fetchDistrictsDefinition = defineAction({
  ...fetchDistrictsAction,
  input: object({
    level: enumerated({
      macroRegion: 'macroRegion',
      mesoRegion: 'mesoRegion',
      microRegion: 'microRegion',
      state: 'state',
      city: 'city',
    }).isOptional(),
    ibgeCodes: string(),
  }),
  output: object({}),
})

export default fetchDistrictsDefinition
