import { GeoJsonLineString, GeoJsonMultiPolygon } from '~/prix/types/geoJson'
import countriesQuery from './levels/countries.query'
import statesQuery from './levels/states.query'
import macroRegionsQuery from './levels/macroRegions.query'
import mesoRegionsQuery from './levels/mesoRegions.query'
import microRegionsQuery from './levels/microRegions.query'
import citiesQuery from './levels/cities.query'
import neighborhoodsQuery from './levels/neighborhoods.query'
import streetsQuery from './levels/streets.query'
import legalEntityQuery from './levels/legalEntity.query'
import AdditionalInformation from './additionalInformation/additionalInformation.component'

export type GeoChildrenLegalEntitiesPossibilities =
  | 'countries'
  | 'macroRegions'
  | 'states'
  | 'mesoRegions'
  | 'microRegions'
  | 'cities'
  | 'neighborhoods'
  | 'streets'
  | 'clusteredLegalEntities'
  | 'legalEntities'
  | 'legalEntity'
  | 'streetWithClusters'

export type GeoLegalEntitiesPossibilities =
  | 'country'
  | 'macroRegion'
  | 'state'
  | 'mesoRegion'
  | 'microRegion'
  | 'city'
  | 'neighborhood'
  | 'street'
  | 'clusteredLegalEntity'
  | 'legalEntity'

export type GeoLevel =
  | 'country'
  | 'macroRegion'
  | 'state'
  | 'mesoRegion'
  | 'microRegion'
  | 'city'
  | 'neighborhood'
  | 'street'

export interface GeoItem {
  [x: string]: string
  id: string
  key: string
  name: string
  multiPolygon: GeoJsonMultiPolygon | null
  path: GeoJsonLineString | null
  collection?: any | null //for now
  center?: google.maps.LatLng | null
  cluster?: google.maps.LatLng | null
  clusterItems?: Array<{
    id: string
    name: string
    highlight: boolean | null
  }> | null
  color: string
  borderColor: string
  count: number
  highlight: number | null
  levelKey: GeoChildrenLegalEntitiesPossibilities
  percentColor: string
  legendValues: Array<{
    maxValue: number
    minValue: number
  }>
  updateYearCensus: number | null
}

export interface Level {
  geo: GeoLegalEntitiesPossibilities
  childrenGeoLevel?: GeoChildrenLegalEntitiesPossibilities
  id: string | null
  roles?: boolean
}

export interface Breadcrumb extends Level {
  title: string
  subTitle: string
}

export interface FilterHighlight {
  type: string
  name?: string
  value?: string | boolean | null
  attendanceSource?: string | null
  eadOption?: string | null
  courseProduct?: string | null
}

export interface AdditionalInformation {
  title: string
  value?: string | number | null
}

export const mapLevels: GeoChildrenLegalEntitiesPossibilities[] = [
  'countries',
  'macroRegions',
  'states',
  'mesoRegions',
  'microRegions',
  'cities',
  'neighborhoods',
  'streets',
  'clusteredLegalEntities',
  'legalEntities',
  'legalEntity',
]

export const levelsLabels = {
  countries: null,
  macroRegions: 'Macrorregião',
  states: 'Estado',
  mesoRegions: 'Mesorregião',
  microRegions: 'Microrregião',
  cities: 'Cidade',
  neighborhoods: 'Bairro',
  streets: null,
  clusteredLegalEntities: null,
  legalEntities: null,
  legalEntity: null,
}

export const geoLevelToGeoprocessingColumn = {
  countries: 'countryId',
  macroRegions: 'macroRegionId',
  states: 'stateId',
  mesoRegions: 'mesoRegionId',
  microRegions: 'microRegionId',
  cities: 'cityId',
  neighborhoods: 'neighborhoodId',
  streets: 'streetId',
  streetWithClusters: 'streetId',
  legalEntities: 'streetId',
  legalEntity: 'legalEntityId',
}

export const geoLevelToGeoItem = {
  countries: 'country',
  macroRegions: 'macroRegion',
  states: 'state',
  mesoRegions: 'mesoRegion',
  microRegions: 'microRegion',
  cities: 'city',
  neighborhoods: 'neighborhood',
  streets: 'street',
  clusteredLegalEntities: null,
  streetWithClusters: null,
  legalEntities: null,
  legalEntity: null,
}

export const geoQueries = {
  countries: countriesQuery,
  macroRegions: macroRegionsQuery,
  states: statesQuery,
  mesoRegions: mesoRegionsQuery,
  microRegions: microRegionsQuery,
  cities: citiesQuery,
  neighborhoods: neighborhoodsQuery,
  streets: streetsQuery,
  legalEntities: legalEntityQuery,
}

export const optionsSingleStringValue = [
  'size',
  'cnaes',
  'segment',
  'legalNatureId',
  'attendance',
  'area',
  'perCapta',
  'profits',
  'cost',
  'scoreOperationsManagement',
  'scoreTransformation',
  'scoreIndicatorsManagement',
  'scoreInnovation',
  'scoreMarketing',
  'scoreSustainablePractices',
]
export const optionsBooleanValue = ['isMei', 'isSimples']

export const mapLevelEntities = {
  countries: 'country' as 'country',
  macroRegions: 'macroRegion' as 'macroRegion',
  states: 'state' as 'state',
  mesoRegions: 'mesoRegion' as 'mesoRegion',
  microRegions: 'microRegion' as 'microRegion',
  cities: 'city' as 'city',
  neighborhoods: 'neighborhood' as 'neighborhood',
  streets: 'street' as 'street',
  clusteredLegalEntities: 'legalEntityGeoprocessing' as 'legalEntityGeoprocessing',
  legalEntities: 'legalEntityGeoprocessing' as 'legalEntityGeoprocessing',
  legalEntity: 'legalEntityGeoprocessing' as 'legalEntityGeoprocessing',
}

export const mapLevelColumnsIds = {
  countries: 'countryId' as 'countryId',
  macroRegions: 'macroRegionId' as 'macroRegionId',
  states: 'stateId' as 'stateId',
  mesoRegions: 'mesoRegionId' as 'mesoRegionId',
  microRegions: 'microRegionId' as 'microRegionId',
  cities: 'cityId' as 'cityId',
  neighborhoods: 'neighborhoodId' as 'neighborhoodId',
  streets: 'streetId' as 'streetId',
  clusteredLegalEntities: 'legalEntityGeoprocessing' as 'legalEntityGeoprocessing',
  legalEntities: 'legalEntityGeoprocessing' as 'legalEntityGeoprocessing',
  legalEntity: 'legalEntityGeoprocessing' as 'legalEntityGeoprocessing',
}

export function nextLevel(
  currentLevel: GeoChildrenLegalEntitiesPossibilities,
): GeoChildrenLegalEntitiesPossibilities {
  const currentIndex = mapLevels.indexOf(currentLevel)
  return mapLevels[currentIndex + 1]
}
