import { defineAction, object, string, enumerated } from '~/prix'
import fetchAllDistricts from './fetchAllDistricts.action.json'

const fetchAllDistrictsDefinition = defineAction({
  ...fetchAllDistricts,
  input: object({
    level: enumerated({
      macroRegion: 'macroRegion',
      mesoRegion: 'mesoRegion',
      microRegion: 'microRegion',
      state: 'state',
      city: 'city',
    }).isOptional(),
    ibgeCodes: string(),
  }),
  output: object({}),
})

export default fetchAllDistrictsDefinition
