import { defineAction, object, enumerated, string } from '~/prix'
import fetchStreetsAndWays from './fetchAllStreetsAndWays.action.json' assert { type: 'json' }

const fetchStreetsAndWaysDefinition = defineAction({
  ...fetchStreetsAndWays,
  input: object({
    level: enumerated({
      macroRegion: 'macroRegion',
      mesoRegion: 'mesoRegion',
      microRegion: 'microRegion',
      state: 'state',
      city: 'city',
    }).isOptional(),
    ibgeCodes: string(),
  }),
  output: object({}),
})

export default fetchStreetsAndWaysDefinition
