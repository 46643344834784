import { array, defineAction, number, object, string } from '~/prix'
import searchAddressAction from './searchAddress.action.json'

const searchAddressDefinition = defineAction({
  ...searchAddressAction,
  input: object({
    address: string().label('Endereço parcial a ser geocodificado.'),
    include: array(string()).label('Campos adicionais a serem incluídos na resposta.'),
    limit: number().label('Número máximo de resultados a serem retornados.'),
  }),
  output: object({}),
})

export default searchAddressDefinition
