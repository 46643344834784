import { defineAction, enumerated, object, string } from '~/prix'
import processAddressesEmbeddingsAction from './processAddressesEmbeddings.action.json'

const processAddressesEmbeddingsDefinition = defineAction({
  ...processAddressesEmbeddingsAction,
  input: object({
    level: enumerated({
      macroRegion: 'macroRegion',
      mesoRegion: 'mesoRegion',
      microRegion: 'microRegion',
      state: 'state',
      city: 'city',
    }).isOptional(),
    ibgeCodes: string(),
  }),
  output: object({}),
})

export default processAddressesEmbeddingsDefinition
