import { defineEntity, entity, foreign, object, string } from '~/prix'

const districtStreetIntersectionEntity = defineEntity({
  key: 'districtStreetIntersection',
  title: 'Intersecção de rua e distrito',
  sources: ['default'],
  schema: object({
    id: string().label('ID', 'Identificador', 'Código'),
    streetId: foreign(entity('street').property('id')),
    districtId: foreign(entity('district').property('id')),
  }),
})

export default districtStreetIntersectionEntity
