import { defineAction, object, boolean, array, string } from '~/prix'
import fetchShapesFromOpenStreetMapAction from './fetchShapesFromOpenStreetMap.action.json'

const fetchShapesFromOpenStreetMapDefinition = defineAction({
  ...fetchShapesFromOpenStreetMapAction,
  input: object({
    update: boolean()
      .default(() => false)
      .label('Atualizar registros'),
    citesToProcess: array(string().label('Id de cidades a serem processadas')),
  }),
  output: object({}),
})

export default fetchShapesFromOpenStreetMapDefinition
