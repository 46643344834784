import { array, defineAction, object, string } from '~/prix'
import neighborhoodStreetIntersectionProcessAction from './neighborhoodStreetIntersectionProcess.action.json' assert { type: 'json' }

const neighborhoodStreetIntersectionProcessDefinition = defineAction({
  ...neighborhoodStreetIntersectionProcessAction,
  input: object({
    citiesToProcess: array(string().label('ID de cidade a ser processada')),
  }),
  output: object({}),
})

export default neighborhoodStreetIntersectionProcessDefinition
