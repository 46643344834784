import { defineAction, object, string } from '~/prix'
import geocodeAddressAction from './geocodeAddress.action.json'

// city, country, neighborhood, number, state, street

const geocodeAddressDefinition = defineAction({
  ...geocodeAddressAction,
  input: object({
    address: string().label('Endereço completo a ser geocodificado.'),
    city: string().label('Nome da cidade'),
    country: string().label('Nome do país'),
    neighborhood: string().label('Nome do bairro'),
    number: string().label('Número da casa'),
    state: string().label('Nome ou abreviatura de estado'),
    street: string().label('Nome da rua'),
    complement: string().label('Complemento do endereço'),
    postalCode: string().label('CEP ou código postal do endereço'),
  }),
  output: object({}),
})

export default geocodeAddressDefinition
