import { defineAction, object } from '~/prix'
import districtStreetIntersectionProcessAction from './districtStreetIntersectionProcess.action.json' assert { type: 'json' }

const districtStreetIntersectionProcessDefinition = defineAction({
  ...districtStreetIntersectionProcessAction,
  input: object({}),
  output: object({}),
})

export default districtStreetIntersectionProcessDefinition
