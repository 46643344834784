import { array, number, boolean, defineAction, object, string } from '~/prix'
import internalGeocodeAction from './internalGeocode.action.json'

const internalGeocodeDefinition = defineAction({
  ...internalGeocodeAction,
  input: object({
    cityName: string().isOptional().label('Nome da cidade que se deseja geocodificar.'),
    stateAbbreviation: string()
      .isOptional()
      .label('Abreviação do estado que se deseja geocodificar.'),
    ibgeCodes: array(string().isOptional().label('Nome da cidade que se deseja geocodificar.')),
    concurrency: number().label('Quantidade de cidades a se processar de forma concorrente.'),
    onlyNotDeprecated: boolean()
      .isOptional()
      .label('Geocodificar apenas cidades com topologias obsoletas.'),
    saveLogsToFolder: string()
      .isOptional()
      .label('Pasta onde se deve salvar os logs de progresso e erros.'),
    update: boolean()
      .isOptional()
      .label('Geocodificar todos os endereços na legal_entity e não somente os nulos.'),
  }),
  output: object({}),
})

export default internalGeocodeDefinition
