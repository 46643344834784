import { defineAction, enumerated, object, string } from '~/prix'
import seedStreetRoadReferenceAction from './seedStreetRoadReference.action.json'

const seedStreetRoadReferenceDefinition = defineAction({
  ...seedStreetRoadReferenceAction,
  input: object({
    level: enumerated({
      macroRegion: 'macroRegion',
      mesoRegion: 'mesoRegion',
      microRegion: 'microRegion',
      state: 'state',
      city: 'city',
    }).isOptional(),
    ibgeCodes: string(),
  }),
  output: object({}),
})

export default seedStreetRoadReferenceDefinition
