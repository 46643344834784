import { defineAction, object, string, enumerated } from '~/prix'
import fetchAllSuburbs from './fetchAllSuburbs.action.json'

const fetchAllSuburbsDefinition = defineAction({
  ...fetchAllSuburbs,
  input: object({
    level: enumerated({
      macroRegion: 'macroRegion',
      mesoRegion: 'mesoRegion',
      microRegion: 'microRegion',
      state: 'state',
      city: 'city',
    }).isOptional(),
    ibgeCodes: string(),
  }),
  output: object({}),
})

export default fetchAllSuburbsDefinition
