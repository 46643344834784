import { defineAction, object, string } from '~/prix'
import fetchSuburbs from './fetchSuburbs.action.json'

const fetchSuburbsDefinition = defineAction({
  ...fetchSuburbs,
  input: object({
    ibgeCode: string().label('Código IBGE da cidade.'),
  }),
  output: object({}),
})

export default fetchSuburbsDefinition
