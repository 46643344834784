declare const DocumentTouch: Function

// export const polyfill =
//   'https://polyfill.io/v3/polyfill.min.js?features=RegExp.prototype.flags%2Cdefault%2Ces2015%2Ces2016%2Ces2017%2Ces2018%2Ces2019%2Cblissfuljs%2CResizeObserver%2CMap%2CSet%2CAbortController%2CIntersectionObserver%2CIntersectionObserverEntry%2CString.prototype.padEnd%2CString.prototype.padStart%2CString.prototype.normalize'

export const apiBasePath = '/api/v1'

export const stagingSiteHostnames = ['prixsebraedev.com', 'prixsebraedev.com.br']

export const productionSiteHostnames = ['prixsebrae.com', 'prixsebrae.com.br']

export const siteHostnames = [...productionSiteHostnames, ...stagingSiteHostnames]
export const primarySiteHost = `https://${siteHostnames[0]}`

export const networkBindingHostname = '0.0.0.0'
export const localHostname = 'localhost'
export const localPort = String(
  typeof process !== 'undefined'
    ? process.env.PRIX_SEBRAE_WEB_APP_PORT || 3000
    : typeof window !== 'undefined'
    ? window.location.port
    : '',
)
export const localHost = `http://${localHostname}${localPort ? `:${localPort}` : ''}`

export type Environment = 'development' | 'production' | 'staging'
export const environmentOnBackend = (
  typeof process !== 'undefined' ? process.env.ENVIRONMENT || null : null
) as Environment | null
export const environmentOnFrontend = (() => {
  if (typeof window === 'undefined') {
    return null
  }
  if (window.location.hostname === localHostname) {
    return 'development'
  }
  if (stagingSiteHostnames.includes(window.location.hostname)) {
    return 'staging'
  }
  if (productionSiteHostnames.includes(window.location.hostname)) {
    return 'production'
  }
  return null
})()
export const environment = environmentOnBackend || environmentOnFrontend || 'development'

function checkIsTouchDevice() {
  if (typeof window === 'undefined') {
    return false
  }
  if ((window as any).DocumentTouch && document instanceof DocumentTouch) {
    return true
  }

  if (!window.matchMedia) {
    return false
  }

  const prefixes = ['', '-webkit-', '-moz-', '-o-', '-ms-']
  const queries = prefixes.map(prefix => `(${prefix}touch-enabled)`)

  return window.matchMedia(queries.join(',')).matches
}

export const isTouchDevice = checkIsTouchDevice()

//Service worker
export const isServiceWorkerSupported =
  typeof window !== 'undefined' && window.navigator && 'serviceWorker' in window.navigator

//Navigator.storage.estimate
export const NavigatorStorageSupported =
  typeof window !== 'undefined' && navigator.serviceWorker !== undefined

export const isDevelopment = process.env.NODE_ENV === 'development'
