import { defineAction, enumerated, object, string } from '~/prix'
import fetchOpenStreetMapId from './fetchOpenStreetMapId.action.json' assert { type: 'json' }

const fetchOpenStreetMapIdDefinition = defineAction({
  ...fetchOpenStreetMapId,
  input: object({
    level: enumerated({
      macroRegion: 'macroRegion',
      mesoRegion: 'mesoRegion',
      microRegion: 'microRegion',
      state: 'state',
      city: 'city',
    }).isOptional(),
    ibgeCodes: string(),
  }),
  output: object({}),
})

export default fetchOpenStreetMapIdDefinition
