/* Este arquivo é gerado automaticamente */

import attendantEntity from './attendant/attendant.entity'
import attendantServiceUnitEntity from './attendantServiceUnit/attendantServiceUnit.entity'
import cityGovernmentTransparencyEntity from './cityGovernmentTransparency/cityGovernmentTransparency.entity'
import cityCensusByAreaEntity from './cityIbgeCensus/cityCensusByArea/cityCensusByArea.entity'
import cityCensusByPopulationEntityEntity from './cityIbgeCensus/cityCensusByPopulation/cityCensusByPopulationEntity.entity'
import cityIbgeCensusEntity from './cityIbgeCensus/cityIbgeCensus.entity'
import cnaeEntity from './cnae/cnae.entity'
import legalEntityUnnestCnaeEntity from './cnae/legalEntityUnnestCnae/legalEntityUnnestCnae.entity'
import cnaeClassEntity from './cnaeClass/cnaeClass.entity'
import cnaeDivisionEntity from './cnaeDivision/cnaeDivision.entity'
import cnaeGroupEntity from './cnaeGroup/cnaeGroup.entity'
import cnaeSectionEntity from './cnaeSection/cnaeSection.entity'
import cnaeSegmentEntity from './cnaeSegment/cnaeSegment.entity'
import cnaeSubclassEntity from './cnaeSubclass/cnaeSubclass.entity'
import courseProductEntity from './courseProduct/courseProduct.entity'
import courseRegisterIndividualEntity from './courseRegisterIndividual/courseRegisterIndividual.entity'
import dataSourceEntity from './dataSource/dataSource.entity'
import establishmentEntity from './establishment/establishment.entity'
import cityEntity from './geo/city/city.entity'
import countryEntity from './geo/country/country.entity'
import macroRegionEntity from './geo/macroRegion/macroRegion.entity'
import mesoRegionEntity from './geo/mesoRegion/mesoRegion.entity'
import microRegionEntity from './geo/microRegion/microRegion.entity'
import neighborhoodEntity from './geo/neighborhood/neighborhood.entity'
import stateEntity from './geo/state/state.entity'
import streetEntity from './geo/street/street.entity'
import districtStreetIntersectionEntity from './geo/street/streetIntersection/districtStreetIntersetion/districtStreetIntersection.entity'
import neighborhoodStreetIntersectionEntity from './geo/street/streetIntersection/neighborhoodStreetIntersetion/neighborhoodStreetIntersection.entity'
import wayEntity from './geo/way/way.entity'
import individualEntity from './individual/individual.entity'
import legalEntityDataSourceEntity from './legalEntity/dataSource/legalEntityDataSource.entity'
import legalEntityEntity from './legalEntity/legalEntity.entity'
import disproportionateAttendancesEntity from './legalEntityAttendance/disproportionateAttention/disproportionateAttendances/disproportionateAttendances.entity'
import disproportionatePublicEntity from './legalEntityAttendance/disproportionateAttention/disproportionatePublic/disproportionatePublic.entity'
import legalEntityAttendanceEntity from './legalEntityAttendance/legalEntityAttendance.entity'
import legalEntityAttendanceLastMonthEntity from './legalEntityAttendance/legalEntityAttendanceLastMonth/legalEntityAttendanceLastMonth.entity'
import legalEntityAttendanceLastYearEntity from './legalEntityAttendance/legalEntityAttendanceLastYear/legalEntityAttendanceLastYear.entity'
import legalEntityAttendanceAttendantEntity from './legalEntityAttendanceAttendant/legalEntityAttendanceAttendant.entity'
import legalEntityAttendanceDataSourceEntity from './legalEntityAttendanceDataSource/legalEntityAttendanceDataSource.entity'
import legalEntityCnaeEntity from './legalEntityCnae/legalEntityCnae.entity'
import legalEntityGeoprocessingEntity from './legalEntityGeoprocessing/legalEntityGeoprocessing.entity'
import legalEntityIndividualEntity from './legalEntityIndividual/legalEntityIndividual.entity'
import legalEntityIndividualDataSourceEntity from './legalEntityIndividualDataSource/legalEntityIndividualDataSource.entity'
import legalEntityIndicatorsEntity from './legalEntityProfit/legalEntityIndicators.entity'
import legalNatureEntity from './legalNature/legalNature.entity'
import reportEntity from './report/report.entity'
import reportOutputEntity from './reportOutput/reportOutput.entity'
import segmentEntity from './segment/segment.entity'
import serviceUnitGeoprocessingEntity from './serviceUnitGeoprocessing/serviceUnitGeoprocessing.entity'
import summaryEntity from './summary/summary.entity'
import timeSeriesEntity from './timeSeries/timeSeries.entity'
import userLogEntity from './userLog/userLog.entity'

export default [
  attendantEntity,
  attendantServiceUnitEntity,
  cityGovernmentTransparencyEntity,
  cityCensusByAreaEntity,
  cityCensusByPopulationEntityEntity,
  cityIbgeCensusEntity,
  cnaeEntity,
  legalEntityUnnestCnaeEntity,
  cnaeClassEntity,
  cnaeDivisionEntity,
  cnaeGroupEntity,
  cnaeSectionEntity,
  cnaeSegmentEntity,
  cnaeSubclassEntity,
  courseProductEntity,
  courseRegisterIndividualEntity,
  dataSourceEntity,
  establishmentEntity,
  cityEntity,
  countryEntity,
  macroRegionEntity,
  mesoRegionEntity,
  microRegionEntity,
  neighborhoodEntity,
  stateEntity,
  streetEntity,
  districtStreetIntersectionEntity,
  neighborhoodStreetIntersectionEntity,
  wayEntity,
  individualEntity,
  legalEntityDataSourceEntity,
  legalEntityEntity,
  disproportionateAttendancesEntity,
  disproportionatePublicEntity,
  legalEntityAttendanceEntity,
  legalEntityAttendanceLastMonthEntity,
  legalEntityAttendanceLastYearEntity,
  legalEntityAttendanceAttendantEntity,
  legalEntityAttendanceDataSourceEntity,
  legalEntityCnaeEntity,
  legalEntityGeoprocessingEntity,
  legalEntityIndividualEntity,
  legalEntityIndividualDataSourceEntity,
  legalEntityIndicatorsEntity,
  legalNatureEntity,
  reportEntity,
  reportOutputEntity,
  segmentEntity,
  serviceUnitGeoprocessingEntity,
  summaryEntity,
  timeSeriesEntity,
  userLogEntity
]
