import { defineAction, object, enumerated, string } from '~/prix'
import fetchBuildingsAction from './fetchBuildings.action.json'

/**

CREATE TYPE building_height_source AS ENUM(
	'unknown',
	'manual',
	'ai',
	'photogrammetry',
	'lidar'
);

CREATE TABLE "building"
(
    id serial NOT NULL,
    center Geometry(Point, 4326),
    boundary Geometry(Multipolygon, 4326),
    open_street_map_id VARCHAR,
    name varchar(255),
    part_id integer,
    origin_id integer,
    height float,
    height_source building_height_source,
    minimum_height float,
    color VARCHAR(100),
    material VARCHAR(255),
    shape VARCHAR(255),
    roof_shape VARCHAR(255),
    roof_height float,
    roof_level VARCHAR(255),
    roof_color VARCHAR(100),
    roof_material VARCHAR(255),
    roof_direction integer,
    roof_angle integer,
    updated_at timestamp,
    built_at timestamp,
    city_id integer,
    CONSTRAINT building_pk PRIMARY KEY (id),
    CONSTRAINT building_fk 
      FOREIGN KEY (city_id) REFERENCES city
);

CREATE UNIQUE INDEX building_open_street_map_code ON building(open_street_map_id, city_id);
*/
const fetchBuildingsDefinition = defineAction({
  ...fetchBuildingsAction,
  input: object({
    level: enumerated({
      macroRegion: 'macroRegion',
      mesoRegion: 'mesoRegion',
      microRegion: 'microRegion',
      state: 'state',
      city: 'city',
    }).isOptional(),
    ibgeCodes: string(),
  }),
  output: object({}),
})

export default fetchBuildingsDefinition
