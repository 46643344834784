import { defineAction, object, boolean, enumerated, string } from '~/prix'
import fetchAllRoadsAction from './fetchAllRoads.action.json'

const fetchAllRoadsDefinition = defineAction({
  ...fetchAllRoadsAction,
  input: object({
    level: enumerated({
      macroRegion: 'macroRegion',
      mesoRegion: 'mesoRegion',
      microRegion: 'microRegion',
      state: 'state',
      city: 'city',
    }).isOptional(),
    ibgeCodes: string(),
  }),
  output: object({}),
})

export default fetchAllRoadsDefinition
